<template>
  <div class="c-explore-title" :class="[`c-explore-title--flex-direction--${flexDirection}`]">
    <div class="c-explore-title__left">
      <span class="c-explore-title__left__main" :class="[`c-explore-title__left__main--${color}`]">
        {{ $attrs.title }}
      </span>
      <span>
        <slot name="extra"></slot>
      </span>
    </div>

    <div class="c-explore-title__right">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ExploreTitle',
  props: {
    color: {
      type: String,
      default: 'black',
    },
    flexDirection: {
      type: String,
      default: 'row',
    },

  },
});
</script>
<style lang="scss" scoped>
.c-explore-title {
  margin-bottom: 20px;

  &--flex-direction {
    &--row {
      @include flex(space-between, flex-start);
    }

    &--column {
      @include flex(space-between, flex-start, column);
    }
  }

  &__left {
    @include flex();

    &__main {
      display: inline-block;
      @include font-style($c-black, 24, bold, 1.2px);

      &--white {
        color: $c-white;
      }
    }
  }

  &__right {
    @include flex();
  }
}

@media screen and (min-width: $tablet) {
  .c-explore-title {
    @include flex(space-between);
    margin-bottom: 36px;
  }
}
</style>
