<template>
  <div class="v-labels-info">
    <explore-info-title bg="labels">
      {{ route.params.category }}
    </explore-info-title>

    <div class="label-intro">
      <div class="label-intro__content g-content">
        <div class="label-intro__content__left">
          <div class="curr-label">
            <img class="curr-label__img" src="@/assets/explore/labels_img.png" />
          </div>
          <p class="curr-label-desc">
            The best writers and performers at the best recording studios in the world. The highest quality music for
            film and TV recorded, mixed and mastered at the world famous Abbey Road Studios.
          </p>
        </div>
        <div class="label-intro__content__right">
          <div class="featured-tracks label-intro-wrap">
            <p class="label-intro-wrap__text">Feature Tracks</p>

            <div class="info-tracks">
              <info-tracks />
            </div>
          </div>

          <p class="label-intro-wrap__text">Related Labels</p>

          <div class="label-box-list-wrap">
            <ul class="label-box-list">
              <li class="label-box-list__item" v-for="item of mocklabelBoxs" :key="item.title">
                <div class="label-box-list__item__box">
                  <labels-box :data="item" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="normal">
      <div class="normal__content g-content">
        <normal-box-rwd-transform :data="normalAlbumMockData">
          <template v-slot:header>
            <explore-title title="所有專輯" flexDirection="column">
              <template v-slot:extra>
                <SeeMore text="See all in search" />
              </template>
              <template v-slot:right>
                <div class="select-wrap">
                  <mi-select v-model:value="sortValue" :options="mockOptions" label="Sort by" flexDirection="column" />
                  <mi-select
                    v-model:value="categoryValue"
                    :options="mockOptions"
                    label="Category"
                    flexDirection="column"
                  />
                </div>
              </template>
            </explore-title>
          </template>
          <template v-slot="albumData">
            <albums-box-normal color="white" :data="albumData.data" />
          </template>
        </normal-box-rwd-transform>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';

// import Breadcrumb from '@/views/Explore/Breadcrumb.vue';
import ExploreTitle from '@/components/Explore/ExploreTitle.vue';
import AlbumsBoxNormal from '@/components/Explore/AlbumsBoxNormal.vue';
// import GenresBoxPopular from '@/components/Explore/GenresBoxPopular.vue';
import SeeMore from '@/components/Global/SeeMore.vue';
import MiSelect from '@/components/Global/Select/index.vue';
import ExploreInfoTitle from '@/components/Explore/ExploreInfoTitle.vue';
import InfoTracks from '@/components/Explore/InfoTracks.vue';
import img1 from '@/assets/explore/labels_img.png';
import img2 from '@/assets/explore/labels_img_2.png';
import img3 from '@/assets/explore/labels_img_3.png';
import img4 from '@/assets/explore/labels_img_4.png';
import LabelsBox from '@/components/Explore/LabelsBox.vue';
import NormalBoxRwdTransform from '@/components/Global/NormalBoxRwdTransform.vue';
import { normalAlbumMockData } from '@/config/mockData/albumsBox';

export default defineComponent({
  name: 'LabelsInfo',
  components: {
    // Breadcrumb,
    LabelsBox,
    ExploreTitle,
    AlbumsBoxNormal,
    // GenresBoxPopular,
    SeeMore,
    MiSelect,
    InfoTracks,
    ExploreInfoTitle,
    NormalBoxRwdTransform,
  },
  setup() {
    const sortValue = ref('name');
    const categoryValue = ref('newest');
    const route = useRoute();

    const mockOptions = [
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'Newest',
        value: 'newest',
      },
      {
        label: 'Oldest',
        value: 'oldest',
      },
    ];

    const mocklabelBoxs = [
      {
        imgPath: img1,
        title: 'NIKE',
        desc: 'Created by composer, Benson Taylor, A Remarkable Idea is an artist-driven initiative, …',
      },
      {
        imgPath: img2,
        title: 'ADDIDA',
        desc: 'Created by composer, Benson Taylor, A Remarkable Idea is an artist-driven initiative, …',
      },
      {
        imgPath: img3,
        title: 'MIZUNO',
        desc: 'Created by composer, Benson Taylor, A Remarkable Idea is an artist-driven initiative, …',
      },
      {
        imgPath: img4,
        title: 'UNDER ARMOUR',
        desc: 'Created by composer, Benson Taylor, A Remarkable Idea is an artist-driven initiative, …',
      },
    ];

    return {
      route,
      sortValue,
      categoryValue,
      mockOptions,
      mocklabelBoxs,
      normalAlbumMockData,

    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/musiclist.scss';
@import '~@/styles/share/labels.scss';
@import '~@/styles/share/albumsAndPlaylists.scss';

.v-labels-info {
  background-color: $c-white3;
  position: relative;
}

.label-intro {
  padding-top: 60px;
  background-image: url('~@/assets/contact/img_deco.png');
  background-size: 609px;
  background-repeat: no-repeat;
  background-position: bottom right;

  &__content {
    @include flex(flex-start, flex-start, column);

    &__left {
      flex: none;
      width: 100%;
    }

    &__right {
      // flex: 1;
      width: 100%;
    }
  }
}

.all-albums-content {
  margin-top: 100px;
  padding-bottom: 130px;
}

.curr-label {
  &__img {
  }
}

.curr-label-desc {
  @include font-style($c-assist6, 16, normal, 0px, 24px);
  margin-top: 40px;
}

.featured-tracks {
  width: 100%;
}

.label-intro-wrap {
  margin-top: 28px;

  &__text {
    @include font-style($c-black, 14, bold, 0.7px, 18px);
    margin-bottom: 8px;
  }
}

.info-tracks {
  margin-top: 12px;
  margin-bottom: 40px;
  background-color: $c-white;
  @include padding(20px 8px);
  border-radius: 5px;
}

.related-labels {
  margin-top: 40px;
}

.related-labels-box {
  border-radius: 5px;
  width: 100%;
}

.related-labels-lists {
  @include flex();
  white-space: nowrap;
  overflow: auto;

  &__item {
    @include padding(25px 20px);
    width: 290px;
    background-color: $c-white;
    white-space: normal;
    flex: none;

    & + & {
      margin-left: 24px;
    }

    &__img {
      width: 125px;
      height: 78px;
      object-fit: contain;
    }

    &__title {
      margin-top: 20px;
      @include font-style($c-black, 14, bold, 0.7px, 18px);
    }

    &__desc {
      margin-top: 8px;
      @include font-style($c-black, 14, bold, 0.7px, 18px);
    }
  }
}

.category-section {
  & + & {
    margin-top: 100px;
  }

  &__content {
  }
}

.feature-track-list {
  width: 100%;

  margin-top: 12px;
  &__item {
    & + & {
      margin-top: 12px;
    }

    &__box {
    }
  }
}

.more-label-box-list {
  &__item {
    width: 100%;

    & + & {
      margin-top: 24px;
    }

    &__box {
      @include padding(0 7.5px);
    }
  }
}

@media screen and (min-width: $tablet) {
  .label-intro {
    padding-bottom: 80px;
  }
  .v-labels-info {
    background-color: $c-white3;
    position: relative;

    &__header {
      height: 270px;
      background-image: url('~@/assets/explore/labels_banner.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center right;
      position: relative;
    }
  }

  .breadcrumb-wrap {
    height: 60px;
    @include flex();
  }

  .explore-title {
    @include position(center);
    text-align: center;

    &__sub {
      @include font-style($c-white, 16, medium, 0.8px);
    }

    &__main {
      margin-top: 12px;
      @include font-style($c-white, 45, bold, 2.25px, 60px);
    }
  }

  .label-intro {
    padding-top: 80px;
    background-image: url('~@/assets/contact/img_deco.png');
    background-size: 609px;
    background-repeat: no-repeat;
    background-position: bottom right;

    &__content {
      @include flex(flex-start, flex-start);

      &__left {
        flex: none;
        width: 200px;
      }

      &__right {
        flex: 1;
        margin-left: 75px;
      }
    }
  }

  .curr-label {
    &__img {
    }
  }

  .curr-label-desc {
    @include font-style($c-assist6, 16, normal, 0px, 24px);
    margin-top: 40px;
  }

  .featured-tracks {
  }

  .label-intro-wrap {
    &__text {
      @include font-style($c-black, 14, bold, 0.7px, 18px);
      margin-bottom: 8px;
    }
  }

  .info-tracks {
    margin-top: 8px;
    @include padding(24px);
  }

  .related-labels {
    margin-top: 40px;
  }

  .related-labels-box {
    @include padding(35px 38px);
    background-color: $c-white;
    border-radius: 5px;
  }

  .related-labels-lists {
    @include flex();
    &__item {
      & + & {
        margin-left: 24px;
      }

      &__img {
        width: 125px;
        height: 78px;
        object-fit: contain;
      }

      &__title {
        margin-top: 20px;
        @include font-style($c-black, 14, bold, 0.7px, 18px);
      }

      &__desc {
        margin-top: 8px;
        @include font-style($c-black, 14, bold, 0.7px, 18px);
      }
    }
  }

  .category-section {
    & + & {
      margin-top: 100px;
    }

    &__content {
    }
  }

  .normal-albums-box-list {
    @include flex();
    flex-wrap: wrap;
    margin: 0 -10px;

    &__item {
      flex: none;
      width: 16.666667%;
      margin-top: 46px;

      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        margin-top: 0px;
      }

      &__box {
        @include padding(0 10px);
      }
    }
  }

  .feature-track-list {
    &__item {
      & + & {
        margin-top: 5px;
      }

      &__box {
      }
    }
  }
}

@media screen and (min-width: $sm-pc) {
  .label-intro {
    &__content {
      &__left {
        width: 350px;
      }
    }
  }
}
</style>
