<template>
  <div
    class="c-see-more"
    :class="[
      {
        'c-see-more--show--init': show.includes('init'),
        'c-see-more--show--tablet': show.includes('tablet'),
      },
    ]"
  >
    <router-link :to="to">
      <span class="c-see-more__text">{{ $attrs.text }} </span>
      <img class="c-see-more__icon" src="@/assets/icon/icon_arrow_red.svg" />
    </router-link>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SeeMore',
  props: {
    to: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Array,
      // 為了應付各種情況，所以有這個奇怪的寫法 ..
      // ['init']: 預設顯示, tablet斷點 隱藏
      // ['tablet']: 預設隱藏, tablet斷點 顯示
      // ['init', 'tablet']: 都顯示
      default: () => ['init', 'tablet'],
    },
  },
});
</script>
<style lang="scss" scoped>
// 這個有點畸形，一般情況手機會
.c-see-more {
  margin-left: 28px;

  &--show {
    &--tablet {
      display: none;
    }

    &--init {
      display: block;
    }
  }

  &__text {
    @include font-style($c-main, 16, 600, 0.8px, 18px);
  }

  &__icon {
    display: inline-block;
    margin-left: 13px;
    width: 5px;
  }
}

@media screen and (min-width: $tablet) {
  .c-see-more {

    cursor: pointer;

    &--show {
      &--init {
        display: none;
      }

      &--tablet {
        display: block;
      }

    }
  }
}
</style>
