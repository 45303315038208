<template>
  <div class="c-explore-info-title" :class="[`c-explore-info-title--bg--${bg}`]">
    <breadcrumb />

    <div class="explore-title">
      <p class="explore-title__sub">探索... 品牌</p>
      <h2 class="explore-title__main">
        <slot></slot>
      </h2>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import Breadcrumb from '@/views/Explore/Breadcrumb.vue';

export default defineComponent({
  name: 'ExporeInfoTitle',
  components: {
    Breadcrumb,
  },
  props: {
    bg: {
      type: String,
      default: '',
      required: true,
    },
  },
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-explore-info-title {
  height: 160px;
  margin-top: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  position: relative;

  &--bg {
    &--genres {
      background-image: url('~@/assets/explore/genres_img.jpg');
    }

    &--moods {
      background-image: url('~@/assets/explore/moods_img.jpg');
    }

    &--labels {
      background-image: url('~@/assets/explore/labels_banner.jpg');
    }
  }
}

.explore-title {
  @include position(center);
  text-align: center;

  &__sub {
    @include font-style($c-white, 16, medium, 0.8px, 20px);
  }

  &__main {
    @include font-style($c-white, 24, bold, 1.2px, 33px);
  }
}

@media screen and (min-width: $tablet) {

  .c-explore-info-title {
      margin-top: 80px;
      height: 274px;
  }
  .explore-title {
    @include position(center);
    text-align: center;

    &__sub {
      @include font-style($c-white, 16, medium, 0.8px, 28px);
    }

    &__main {
      @include font-style($c-white, 45, bold, 2.25px, 60px);
    }
  }
}
</style>
